// Init
(function ($, Foundation) {
  'use strict';
  $(document).ready(function () {
    $(document).foundation();

    //Searchtoggle
    $("#search_toggle").on('click', function(e){
        e.preventDefault();
        $('.topbar__search').toggleClass('active');
    });

    $("#close_toggle").on('click', function(e){
        e.preventDefault();
        $('.topbar__search').toggleClass('active');
    });

    /* disable right mouse button */
    $(function() {
        if ($('body.com_webexposition').length) {
            $(this).bind("contextmenu", function(e) {
                e.preventDefault();
            });
        }
    });

    // newsletter toggle
    $('.brd-newsletter').on('click', '.newsletter-button', function () {
      $('.inschrijven-succes').slideToggle(1000);
      $(this).addClass('inschrijven-succes');
    });


    if ($('.collapse-article').length) {
      $('.collapse-article').click(function(e){
				if($(this).hasClass('closed')) {
					$(this).attr('aria-expanded', 'true');
				} else {
					$(this).attr('aria-expanded', 'false');
				}
        e.preventDefault();
        $('.collapse-article').toggleClass('closed');
        $('.article-content').toggleClass('open');
      });
    }

		$('#website-load-more').keypress(function (e) {
			var key = e.which;
			if(key == 13)  // the enter key code
			 {
				 $('#website-load-more').click();
				 return false;
			 }
		 });

    if ($('.com_mediabank').length > 0) {

      var isLibrary = $('.mediabank-library').length > 0;

        var getMetadataFieldValue = function (metadata, fieldName) {
            return _.reduce(metadata, function (result, field, key) {
                if (field.field === fieldName) result = field.value
                return result
            }, {})
        }

      // Set Mediabank options
      angular.element(document).ready(
        function () {
          angular.module('Mediabank.Boot')
            .run(runMediabank);

          runMediabank.$inject = ['gettextCatalog', 'MediabankConfig']

          function runMediabank (gettextCatalog, MediabankConfig) {
            MediabankConfig.whenInitialized().then(
              function () {

                // Set general options
                MediabankConfig
                  .setOption('search.help', false)
                  .setOption('search.placeholder', 'Vul je zoekterm in...')
                  .setOption('search.help', true)
                  .setOption('search.helpUrl', '/hulp-bij-zoeken');

                if (isLibrary) {
                  // Set library options
                  MediabankConfig
                    .setOption('gallery.modes', {id: 'table'})
                    .setOption('gallery.default', 'table')
                    .setOption('gallery.sorting.random', false)
                    .setOption('gallery.sorting.sortfield', 'order_s_title')
                    .setOption('gallery.sorting.direction', 'asc')
                    .setOption('gallery.pagination.sort', true)
                    .setOption('gallery.pagination.endless', false)
                    .setOption('gallery.pagination.top', true)
                    .setOption('gallery.pagination.bottom', true);
                } else {
                  // Set options for other entities
                  var detailModes = _.filter(MediabankConfig.getOption('detail.modes'), function (o) {
                    return _.includes(['metadata', 'assets', 'scenes', 'comments', 'facebook', 'twitter', 'pinterest', 'permalink'], o.id);
                  });
                    detailModes.push({
                        'id': 'download',
                        'label': 'Download',
                        'action': function (detailContext) {
                            var popupContent = $('#download_popup')
                            var popup = new Foundation.Reveal(popupContent)
                            var rightsHolderText = '' // empty text to replace placeholder with
                            var articleAliasPrefix = 'download-'
                            var articleAliasCondition = 'downloadbaarexclvgurpjanssenbreukink'

                            var conditions = getMetadataFieldValue(detailContext.record.metadata, 'conditions')

                            if (Array.isArray(conditions)) {
                                _.pull(conditions, 'downloadbaarexclvgurpjanssenbreukink')// remove this, it's the default
                                articleAliasCondition = conditions.filter(function (item) {
                                    return item
                                })[0]
                            }

                            if (typeof articleAliasCondition === 'undefined') {
                                articleAliasCondition = 'downloadbaarexclvgurpjanssenbreukink'
                            }

                            var articleAlias = articleAliasPrefix + articleAliasCondition
                            var url = '/' + articleAlias + '?tmpl=download'

                            var currentImage = detailContext.record.getCurrentImage()
                            if (!currentImage && detailContext.record.asset[0]) {
                                currentImage = detailContext.record.asset[0]
                            }

                            if (currentImage) {
                                var downloadUrl = currentImage.download
                            } else {
                                var downloadUrl = false
                            }

                            // the rightsholder is right there on the page, so reuse the html from the metadata
                            var rightsHolder = detailContext.record.metadata.filter(function(item) {
                                return item.field === 'contact_rechthebbende'
                            })[0]

                            if (typeof rightsHolder === 'undefined') {
                                rightsHolderText = 'Auteursrechthebbende onbekend.'
                            } else {
                                rightsHolderText = rightsHolder.value
                            }

                            // if download is not set we have nothing to download so dont show the link
                            if (!downloadUrl) {
                                url += '&hidelink=true'
                            }

                            // load article and replace template (download.php) variables
                            $.ajax(url)
                                .done(function (resp) {
                                    if (downloadUrl) {
                                        resp = resp.replace('{BRD_DOWNLOAD_URL}', 'href="' + downloadUrl + '"')
                                    } else {
                                        resp = resp.replace('{BRD_DOWNLOAD_URL}', '')
                                    }

                                    resp = resp.replace('{BRD_CONTACTDATA}', rightsHolderText)
                                    popupContent.html(resp)
                                    popup.open()
                                })
                                .fail(function () {
                                    alert('Geen download tekst aanwezig voor: ' + articleAlias + ', downloaden niet mogelijk, neem contact op met het Stadsarchief Breda')
                                })
                        }
                    })

                  MediabankConfig
                    .setOption('gallery.modes', [{id: 'horizontal'}, {id: 'gallery'}, {id: 'list'}])
                    .setOption('gallery.default', 'horizontal')
                    .setOption('gallery.pagination.endless', true)
                    .setOption('gallery.pagination.top', true)
                    .setOption('gallery.pagination.bottom', false)
                    .setOption('gallery.pagination.topTemplate', 'pagination/views/directives/pagination-count.html')
                    .setOption('gallery.pagination.sort', true)
                    .setOption('gallery.sorting.random', true)
                    .setOption('detail.modes', detailModes)
                    .setOption('detail.topviewer.buttons', ['zoomIn', 'zoomOut', 'rotatePlus90',
                      'fullscreen', 'navigator', 'colorAdjustment', 'paginationLeft',
                      'paginationRight', 'zoomingSlider'
                    ])
                    .setOption('gallery.tooltips', true);
                }
              }
            );
          }

          angular.bootstrap(document, ['Mediabank.Boot']);
        });
    }

    // Sends event to google analytics specifying which image was downloaded
    $('body').on('click', ".mediabank-detail-download.active a", function (e) {
      var link = $(this).attr('href');

      //if we have a link and google analytics is loaded
      if(link && typeof ga === 'function') {
          e.preventDefault();
          ga('send', 'event', {
              eventCategory: 'Mediabank Download',
              eventAction: 'click',
              eventLabel: link,
              transport: 'beacon', //beacon because some browsers stop javascript when clicking outbound links
              hitCallback: function() {
                //after google analytics has tracked the event we continue to the download.
                window.location = link;
              }
          });
      } else { //just return and let the user download.
          return;
      }
    });

    if (Foundation.MediaQuery.atLeast('medium')) {
      var $topbar = new Foundation.DropdownMenu($('#topbar'), {
        disableHover: true,
        autoclose: false,
        clickOpen: true,
        closeOnClick: true,
        closeOnClickInside: false
      });

      $('.is-dropdown-submenu').removeClass('vertical');
    }

    // Rebuild menu for medium and small screens
    var currentSize = Foundation.MediaQuery.current;
    if (currentSize === 'small') {
      $('#topbar').removeClass('dropdown');
      $('#topbar').removeClass('medium-horizontal');
      var $accordionMenu = new Foundation.AccordionMenu($('#topbar'), {});
      $('.is-accordion-submenu').removeClass('horizontal');
      $('.is-accordion-submenu').addClass('menu vertical nested');
      $('#offCanvas').append($('#topbar'));
    }
    //Remove the is-dropdown-submenu-parent
    $(".is-dropdown-submenu-parent .is-dropdown-submenu-parent").removeClass("is-dropdown-submenu-parent");


      // Carousel article
      var $carousel = $('.picturae-carousel');
      $carousel.slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          arrows: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 6000,
          pauseOnHover: true
      });
  });
}(jQuery, Foundation));


